/* import __COLOCATED_TEMPLATE__ from './editable-name.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable @intercom/intercom/no-component-inheritance */
/* eslint-disable promise/prefer-await-to-then */
import { inject as service } from '@ember/service';
import EditableAttribute from 'embercom/components/people/attributes/editable-attribute';
import { findBy } from '@intercom/pulse/lib/computed-properties';

export default EditableAttribute.extend({
  userUpdaterService: service(),
  attributeService: service(),
  attribute: findBy('attributeService.attributes', 'identifier', 'name'),
  _updateDisplayName() {
    let mainParticipant = this.get('displayedConversation.main_participant');
    if (mainParticipant && mainParticipant.get('user_id') === this.get('person.id')) {
      this.userUpdaterService.updateData(mainParticipant, {
        display_as: this.currentAttributeValue,
      });
    }
    this.person.updateLeadName(this.currentAttributeValue);
  },
  actions: {
    completeEditing(value) {
      if (value !== this.currentAttributeValue) {
        this.updateAttributeValue.perform(value).then(() => this._updateDisplayName());
      }
      this.set('isEditing', false);
    },
  },
});
