/* import __COLOCATED_TEMPLATE__ from './measure-render-time.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
import Component from '@glimmer/component';
import { guidFor } from '@ember/object/internals';

interface Args {
  sampleRate?: number;
  subject: string;
  requiredFeatureFlag?: string;
  version?: string;
}

interface Signature {
  Args: Args;
  Blocks: {
    default: [];
  };
}

export default class MeasureRenderTimeComponent extends Component<Signature> {
  guid;
  isActive = true;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    if (this.args.sampleRate) {
      this.isActive = this.args.sampleRate > Math.random();
    }

    if (this.isActive) {
      this.guid = guidFor(this);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Performance::MeasureRenderTime': typeof MeasureRenderTimeComponent;
    'performance/measure-render-time': typeof MeasureRenderTimeComponent;
  }
}
