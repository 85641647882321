/* import __COLOCATED_TEMPLATE__ from './sidebar.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import BillingConstants, { FIN_AI_COPILOT_BASE_ID } from 'embercom/lib/billing';

export default class Sidebar extends Component {
  @tracked showEditPlanModal = false;
  @service notificationsService;
  @service paywallService;
  @service appService;
  @service quoteService;
  @service selfServeTrialsService;
  @service intl;
  @service customerService;

  get product() {
    return this.args.fakePlan.product;
  }

  get translatedPlanName() {
    return this.intl.t(this.args.fakePlan.get('marketingNameTranslationKey'));
  }

  get noCreditCard() {
    return !this.customerService.customer.hasCard;
  }

  get showLoader() {
    return (
      this.paywallService.shouldUseQuoteService(this.args.fakePlan.id) && this.quoteService.loading
    );
  }

  get app() {
    return this.appService.app;
  }

  get isDowngrade() {
    return this.product.downgrading || this.product.removing;
  }

  get shouldShowCopilotSideBar() {
    if (this.args.isTrialSubscription) {
      return false;
    }
    return this.args.isValidPlanCombination && this.isCopilot;
  }

  get isCopilot() {
    return this.args.fakePlan?.id === FIN_AI_COPILOT_BASE_ID;
  }

  get showFooter() {
    return this.app.onPricing5_1 || !this.isCopilot;
  }

  get pricingArticleLink() {
    return BillingConstants.MODAL_ADD_PLAN_INFO[this.args.fakePlan.idAsNumber]?.pricingArticleLink;
  }

  get allowTrials() {
    if (this.isDowngrade || !this.product.trialable) {
      return false;
    }
    return BillingConstants.MODAL_ADD_PLAN_INFO[this.args.fakePlan.idAsNumber]?.hasOwnProperty(
      'allowTrials',
    )
      ? BillingConstants.MODAL_ADD_PLAN_INFO[this.args.fakePlan.idAsNumber]?.allowTrials
      : true;
  }

  get pricingLinkText() {
    return this.intl.t('paywalls.upgrade-modal.sidebar.learn-about', {
      planName: this.args.fakePlan.name,
    });
  }

  get canTrialNonGraduatingProductTrials() {
    return this.selfServeTrialsService.canTrialSpecificProduct(this.args.fakePlan.id);
  }

  get canTrialNonGraduatingPlanTrials() {
    return this.selfServeTrialsService.canTrialCorePlan(this.args.fakePlan.id);
  }

  get isAddon() {
    return this.args.fakePlan.product.addon;
  }

  get isPlanWithHigherTiers() {
    let product = this.args.fakePlan.product;
    if (!product.activePlan) {
      return product.plans.firstObject;
    }
    return product.plans.objectAt(product.activePlan.tierId);
  }

  get canRequestFreeTrialsViaTickets() {
    return (
      this.app.canSelfManageContract &&
      this.args.fakePlan.product.trialable &&
      (this.isAddon || this.isPlanWithHigherTiers)
    );
  }

  get isSalesforceContracted() {
    return this.app.isSalesforceContracted;
  }

  get billingPeriodDurationInMonths() {
    return this.customerService.customer.subscription.billingPeriodDurationInMonths;
  }

  get onMonthlyPricing5() {
    return this.app.onPricing5 && this.billingPeriodDurationInMonths === 1;
  }

  get onAnnualPricing5() {
    return (
      this.app.onPricing5 &&
      this.billingPeriodDurationInMonths === 12 &&
      !this.app.isSalesforceContracted
    );
  }
}
