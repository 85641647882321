/* import __COLOCATED_TEMPLATE__ from './product-icon-nav-component.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { computed } from '@ember/object';
import { readOnly, gt, and, equal } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { PRODUCTS, INBOX_ID } from 'embercom/lib/billing';

export default Component.extend({
  classNames: ['product-icon'],

  appService: service(),
  router: service(),

  app: readOnly('appService.app'),
  product: null,
  isPlatform: equal('product.id', 'platform'),
  isInbox: equal('product.id', INBOX_ID),

  productKey: computed('product.id', function () {
    let productId = this.get('product.id');
    let product = PRODUCTS[productId];
    return product?.key || productId;
  }),

  svgPath: computed('product.{id,svgId,route}', 'router.currentRouteName', function () {
    let productId = this.get('product.id');
    if (this.get('product.svgId')) {
      let currentRouteName = this.get('router.currentRouteName') || '';
      let productRouteIsActive = this.get('product.route')
        .split(' ')
        .some((routeName) => currentRouteName.includes(routeName));
      if (productRouteIsActive) {
        return `${this.get('product.svgId')}-active`;
      }
      return this.get('product.svgId');
    }
    return `products/id-${productId}-brand-refresh`;
  }),

  hasAnyUnread: gt('app.unreadCount', 0),
  showInboxCount: and('isInbox', 'hasAnyUnread', 'app.inboxIsActive'),
  showPlatformCount: and('isPlatform', 'hasAnyUnread', 'app.inboxIsNotActive'),
  interfaceSvgId: readOnly('product.svgId'),
});
