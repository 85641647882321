/* import __COLOCATED_TEMPLATE__ from './annual-switch-chat-to-sales.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { showNewMessageInIntercomWidget } from 'embercom/lib/intercom-widget-helper';

export default class AnnualSwitchChatToSales extends Component {
  @service intl;
  @service purchaseAnalyticsService;

  get annualDiscountHeading() {
    return this.intl.t('paywalls.upgrade-modal.subscription-upgrade.pricing5.monthly.heading', {
      annualDiscount: this.annualDiscountValue,
    });
  }

  get annualDiscountValue() {
    //TODO: not specified yet. Will likely differ for Essential and Advanced.
    return 'xx';
  }

  @action
  annualSwitchTalkToSales() {
    this.args.postTask();
    showNewMessageInIntercomWidget();

    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      object: 'chat_with_sales_p5_annual_switch',
      planId: this.args.planId,
      context: 'change_plan_modal',
    });
  }
}
