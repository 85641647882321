/* import __COLOCATED_TEMPLATE__ from './product-icon-component.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { computed } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { PRODUCTS } from 'embercom/lib/billing';

export default Component.extend({
  appService: service(),
  app: readOnly('appService.app'),

  classNames: ['product-icon', 'o__brand-refresh'],

  product: null,

  useSmallScale: false,

  optionClass: computed('product', function () {
    let productInfo = PRODUCTS[this.get('product.id')];

    return productInfo && productInfo.optionClass;
  }),

  svgPath: computed('product.id', 'useSmallScale', function () {
    let productId = this.get('product.id');
    let path = `products/id-${productId}`;

    if (!productId) {
      path = 'products/id-platform';
    }

    let scaleSuffix = this.useSmallScale ? '-brand-refresh' : '-default-brand-refresh';
    path += scaleSuffix;
    return path;
  }),
});
