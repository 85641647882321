/* import __COLOCATED_TEMPLATE__ from './delete-credentials-modal.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { task } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';

interface Args {
  credentialsToDelete: any;
  sdkApp: any;
  onModalClose: () => void;
}

interface Signature {
  Args: Args;
  Element: any;
}

export default class DeleteCredentialsModal extends Component<Signature> {
  @service declare notificationsService: any;
  @service declare intl: IntlService;

  @task({ drop: true })
  *deletePushCredential() {
    let pushCredential = this.args.credentialsToDelete;
    try {
      pushCredential.deleteRecord();
      yield pushCredential.save();
      this.args.sdkApp.pushCredentials.removeObject(pushCredential);
      this.notificationsService.notifyConfirmation(
        this.intl.t(
          'apps.app.settings.installation.push-credentials.configuration-modal.remove-push-credentials-success-notification',
        ),
      );
      this.args.onModalClose();
    } catch (error) {
      pushCredential.rollbackAttributes();
      if (error.jqXHR.status === 400) {
        this.notificationsService.notifyError(error.jqXHR.responseJSON.error);
      } else {
        this.notificationsService.notifyError(
          this.intl.t(
            'apps.app.settings.installation.push-credentials.configuration-modal.remove-push-credentials-error-notification',
          ),
        );
      }
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'PushCredentials::DeleteCredentialsModal': typeof DeleteCredentialsModal;
    'push-credentials/delete-credentials-modal': typeof DeleteCredentialsModal;
  }
}
