/* import __COLOCATED_TEMPLATE__ from './table.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

interface Args {
  pushCredentials: $TSFixMe;
  sdkApp: $TSFixMe;
}

interface Signature {
  Args: Args;
  Element: any;
}

export default class PushCredentialsTable extends Component<Signature> {
  @tracked isDeleteCredentialsModalVisible = false;
  @tracked isAndroidConfigurationModalVisible = false;
  @tracked isIOSConfigurationModalVisible = false;
  @tracked selectedPushCredentials: $TSFixMe;
  @tracked sdkApp: $TSFixMe = this.args.sdkApp;

  get shouldDisableCreateButton() {
    return this.args.pushCredentials.any(
      (pushCredential: { bundleId: null }) => pushCredential.bundleId === null,
    );
  }

  @action
  showCreatePushCredentialModal() {
    if (this.sdkApp.isAndroid) {
      this.isAndroidConfigurationModalVisible = true;
    } else {
      this.isIOSConfigurationModalVisible = true;
    }
    this.selectedPushCredentials = null;
  }

  @action
  showEditPushCredentialsModal(pushCredentials: any) {
    this.selectedPushCredentials = pushCredentials;
    if (this.sdkApp.isAndroid) {
      this.isAndroidConfigurationModalVisible = true;
    } else {
      this.isIOSConfigurationModalVisible = true;
    }
  }

  @action
  showDeleteCredentialsModal(pushCredentials: any) {
    this.selectedPushCredentials = pushCredentials;
    this.isDeleteCredentialsModalVisible = true;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'PushCredentials::Table': typeof PushCredentialsTable;
    'push-credentials/table': typeof PushCredentialsTable;
  }
}
