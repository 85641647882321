/* import __COLOCATED_TEMPLATE__ from './formatted-value.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import { computed } from '@ember/object';
import Component from '@ember/component';
export default Component.extend({
  tagName: '',
  emailParts: computed('value', function () {
    return this.value.split('@');
  }),
  stringifiedValue: computed('value', function () {
    return this.value.toString();
  }),

  actions: {
    attributeClicked() {
      this.beginEditing();
    },
  },
});
