/* import __COLOCATED_TEMPLATE__ from './fcm-deprecation-banner.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class FcmDeprecationBanner extends Component {
  @service appService;
  @tracked sdkApp = this.args.sdkApp || {};
  @tracked pushCredentials = this.args.pushCredentials || [];
  @tracked selectedPushCredentials;
  @tracked isAndroidConfigurationModalVisible = false;

  constructor() {
    super(...arguments);
  }

  get isAnyTokenFileNameMissing() {
    let tokenFileNameMissing = false;
    this.pushCredentials.forEach((credential) => {
      if (!credential.tokenFileName) {
        tokenFileNameMissing = true;
      }
    });
    return tokenFileNameMissing;
  }

  @action
  showAndroidConfigModal() {
    if (this.pushCredentials.length > 0) {
      this.selectedPushCredentials = this.pushCredentials.objectAt(0);
      this.isAndroidConfigurationModalVisible = true;
    }
  }
}
