/* import __COLOCATED_TEMPLATE__ from './whatsapp-number-attribute.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
import { isBlank } from '@ember/utils';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import B64FilterParamEncoder from 'embercom/vendor/intercom/b64-filter-param-encoder';
import rawValueToFilterValue from 'embercom/lib/users/user-company-list/raw-value-to-filter-value';

export default class WhatsappNumberAttributeComponent extends Component {
  @service appService;
  @service intl;

  get app() {
    return this.appService.app;
  }

  get person() {
    return this.args.userOrCompany;
  }

  get attribute() {
    return this.args.attribute;
  }

  get identifier() {
    return this.args.attribute.identifier;
  }

  get filterValue() {
    return rawValueToFilterValue(this.identifier, this.value);
  }

  get filterUrl() {
    return B64FilterParamEncoder.filterUrl(this.person, this.attribute, this.filterValue);
  }

  get value() {
    if (this.isNotBlank) {
      return this.person.getValue(this.identifier);
    }
    return this.intl.t('people.attributes.unknown');
  }

  get isNotBlank() {
    if (this.attribute && this.person) {
      return !isBlank(this.person.getValue(this.identifier));
    }
    return false;
  }

  get isBlank() {
    return !this.isNotBlank;
  }

  get whatsappNumberSource() {
    return this.person.whatsapp_number_source;
  }

  get showAutoAssoicationLabel() {
    if (this.whatsappNumberSource === 'known_phone_number') {
      return true;
    }
    return false;
  }
}
