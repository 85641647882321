/* import __COLOCATED_TEMPLATE__ from './configuration-modal.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import type Store from '@ember-data/store';
import type IntlService from 'ember-intl/services/intl';
import { taskFor } from 'ember-concurrency-ts';

interface Args {
  credentialsToEdit: any;
  sdkApp: any;
  onModalClose: () => void;
}

export default class ConfigurationModal extends Component<Args> {
  @service declare store: Store;
  @service declare notificationsService: any;
  @service declare intercomEventService: any;
  @service declare intl: IntlService;

  @tracked editMode = isPresent(this.args.credentialsToEdit);
  @tracked appName = this.editMode ? this.args.credentialsToEdit.mobileAppName : '';
  @tracked bundleId = this.editMode ? this.args.credentialsToEdit.bundleId : '';
  @tracked tokenFileName = this.editMode ? this.args.credentialsToEdit.tokenFileName : '';
  @tracked keyId = this.editMode ? this.args.credentialsToEdit.keyId : '';
  @tracked teamId = this.editMode ? this.args.credentialsToEdit.teamId : '';
  @tracked tokenFile = null;

  get modalTitle() {
    return this.editMode
      ? this.intl.t(
          'apps.app.settings.installation.push-credentials.configuration-modal.edit-title',
        )
      : this.intl.t(
          'apps.app.settings.installation.push-credentials.configuration-modal.create-title',
        );
  }

  get canSaveChanges() {
    let fields = [this.appName, this.bundleId, this.tokenFileName, this.keyId, this.teamId];
    return fields.every((field) => field && field.trim() !== '');
  }

  @action
  triggerTokenFileUpload() {
    let fileInput = document.querySelector('[data-apns-token-file-input]') as HTMLInputElement;
    fileInput.click();
  }

  @action
  savePushCredential() {
    if (this.editMode) {
      taskFor(this.updatePushCredential).perform();
    } else {
      taskFor(this.createPushCredential).perform();
    }
  }

  get isCreatePushCredentialRunning() {
    return taskFor(this.createPushCredential).isRunning;
  }

  get isUpdatePushCredentialRunning() {
    return taskFor(this.updatePushCredential).isRunning;
  }

  @task({ drop: true })
  *createPushCredential() {
    let pushCredential = this.store.createRecord('push-credential', {
      mobileAppName: this.appName,
      bundleId: this.bundleId,
      tokenFileName: this.tokenFileName,
      keyId: this.keyId,
      teamId: this.teamId,
    });

    try {
      pushCredential.tokenFile = this.tokenFile;
      pushCredential.set('sdk_app_id', this.args.sdkApp.id);
      yield pushCredential.save();
      this.args.sdkApp.pushCredentials.pushObject(pushCredential);
      this.notificationsService.notifyConfirmation(
        this.intl.t(
          'apps.app.settings.installation.push-credentials.configuration-modal.success-notification',
        ),
      );
      this.intercomEventService.trackEvent('mobile-push-credential-created', {
        platform: 'iOS',
        owner: 'messenger',
      });
      this.args.onModalClose();
    } catch (error) {
      pushCredential.rollbackAttributes();
      if (error.jqXHR.status === 400) {
        this.notificationsService.notifyError(error.jqXHR.responseJSON.error);
      } else {
        this.notificationsService.notifyError(
          this.intl.t(
            'apps.app.settings.installation.push-credentials.configuration-modal.error-notification',
          ),
        );
      }
    }
  }

  @task({ drop: true })
  *updatePushCredential() {
    let pushCredential = this.args.credentialsToEdit;
    pushCredential.setProperties({
      mobileAppName: this.appName,
      bundleId: this.bundleId,
      tokenFileName: this.tokenFileName,
      keyId: this.keyId,
      teamId: this.teamId,
    });
    if (this.tokenFile) {
      pushCredential.tokenFile = this.tokenFile;
    }
    pushCredential.set('sdk_app_id', this.args.sdkApp.id);

    try {
      yield pushCredential.save();
      this.args.sdkApp.pushCredentials.pushObject(pushCredential);
      this.notificationsService.notifyConfirmation(
        this.intl.t(
          'apps.app.settings.installation.push-credentials.configuration-modal.success-notification',
        ),
      );
      this.intercomEventService.trackEvent('mobile-push-credential-updated', {
        platform: 'iOS',
        owner: 'messenger',
      });
      this.args.onModalClose();
    } catch (error) {
      pushCredential.rollbackAttributes();
      if (error.jqXHR.status === 400) {
        this.notificationsService.notifyError(error.jqXHR.responseJSON.error);
      } else {
        this.notificationsService.notifyError(
          this.intl.t(
            'apps.app.settings.installation.push-credentials.configuration-modal.error-notification',
          ),
        );
      }
    }
  }

  @action
  uploadFile(e: any) {
    let file = e.target.files[0];
    this.tokenFileName = file.name;
    this.tokenFile = file;
  }

  @action
  onCancel() {
    let fileInput = document.querySelector('[data-apns-token-file-input]') as HTMLInputElement;
    if (!fileInput) {
      return;
    }

    fileInput.value = '';
    this.args.onModalClose();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'PushCredentials::Ios::ConfigurationModal': typeof ConfigurationModal;
    'push-credentials/ios/configuration-modal': typeof ConfigurationModal;
  }
}
