/* import __COLOCATED_TEMPLATE__ from './editor.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { replyTypeOptions } from 'embercom/models/posts/post';
import { preferredDevices } from 'embercom/models/data/outbound/constants';
import { inject as service } from '@ember/service';
import { schedule } from '@ember/runloop';

export default class PostEditorComponent extends Component {
  @service contentEditorService;
  @tracked currentDevice = this._firstPreferredDevice();

  get areFollowUpActionsAllowed() {
    return this.args.post.replyType === replyTypeOptions.text;
  }

  _firstPreferredDevice() {
    let contentPlatform = this.args.post.preferredDevices.firstObject;
    switch (contentPlatform) {
      case preferredDevices.ios:
        return 'ios';
      case preferredDevices.android:
        return 'android';
      default:
        return 'web';
    }
  }

  @action
  updateCurrentPreviewDevice(device) {
    schedule('afterRender', () => {
      this.currentDevice = device;
    });
  }
}
