/* import __COLOCATED_TEMPLATE__ from './hover.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class Hover extends Component {
  @service purchaseAnalyticsService;

  @action trackEvent() {
    this.purchaseAnalyticsService.trackEvent({
      action: 'viewed',
      context: 'unknown',
      place: 'paywall',
      object: 'paywall_hover',
      ...this.args.analyticsEventData,
    });
  }
}
