/* import __COLOCATED_TEMPLATE__ from './outlet-wrapper.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */

import templateOnly from '@ember/component/template-only';

interface Signature {
  Blocks: {
    default: [];
  };
}

const ReactOutletWrapper = templateOnly<Signature>();

export default ReactOutletWrapper;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'React::OutletWrapper': typeof ReactOutletWrapper;
    'react/outletwrapper': typeof ReactOutletWrapper;
  }
}
