/* import __COLOCATED_TEMPLATE__ from './editable-attribute.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/use-brace-expansion */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { set } from '@ember/object';
import { copy } from 'ember-copy';
import { isBlank, isPresent } from '@ember/utils';
import { computed, get, action } from '@ember/object';
import { alias, not, notEmpty, and, equal, or } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import DataUpdateContext from 'embercom/models/data/data-update-context';
import AttributeUpdater from 'embercom/components/mixins/people/attribute-updater';
import { task } from 'ember-concurrency';

const KNOWN_DATA_TYPES = ['string', 'integer', 'boolean', 'date', 'float'];

export default Component.extend(AttributeUpdater, {
  classNames: ['flex-none', 'flex', 'flex-row', 'data-test-editable-attribute'],
  appService: service(),
  intercomEventService: service(),
  router: service(),
  app: alias('appService.app'),
  leadQualification: false,
  copiedValue: undefined,
  showConfirmationModal: false,
  conversationsCount: undefined,
  languageSettings: undefined,
  isNotCentered: not('centered'),
  isNotEditing: not('isEditing'),
  isNotDisabled: not('disabled'),
  hasAttributeValue: notEmpty('currentAttributeValue'),
  hasQualificationCompany: and('attribute.isCompany', 'person.qualificationCompany'),
  attributeIdentifiesUser: computed(
    'person.isUserRole',
    'person.user_id',
    'attribute.isEmail',
    function () {
      if (this.get('person.isUserRole') && this.get('attribute.isEmail')) {
        return !this.get('person.user_id');
      }
    },
  ),
  isInbox: computed('currentRouteName', function () {
    let currentRouteName = this.router.get('currentRouteName');
    return isPresent(currentRouteName) && currentRouteName.includes('apps.app.inbox.inbox');
  }),
  disabledForEmail: and('attribute.isEmail', 'hasAttributeValue', 'isInbox'),
  languageSettingsUnavailable: equal('languageSettings', undefined),
  disabledForLanguageOverride: and('attribute.isLanguageOverride', 'languageSettingsUnavailable'),
  disabled: or('person.hasMailingListSource', 'disabledForEmail', 'disabledForLanguageOverride'),
  collectedByOperator: computed('sourceMetadata.source', function () {
    let isOperatorSource = this.get('sourceMetadata.source') === DataUpdateContext.OPERATOR_SOURCE;
    if (this.get('attribute.isCompany')) {
      return isOperatorSource && this.get('sourceMetadata.changed_by') === this.get('person.id');
    }
    return isOperatorSource;
  }),
  collectedByTeammate: equal('sourceMetadata.source', DataUpdateContext.TEAMMATE_SOURCE),
  sourceMetadata: computed('editableObject.metadata', 'attribute', function () {
    return get(
      this,
      `editableObject.metadata.data_sources.${this.get(
        'attribute.identifierWithoutCompanyPrefix',
      )}`,
    );
  }),
  previousValueOrEmpty: computed('sourceMetadata.previous', function () {
    let previous = this.get('sourceMetadata.previous');
    return isBlank(previous) ? 'empty' : `'${previous}'`;
  }),

  editorDataTypeMap: {
    integer: 'numeric',
    float: 'numeric',
    date: 'date',
    string: 'string',
    boolean: 'boolean',
  },

  isUrlValue: computed('currentAttributeValue', function () {
    if (this.currentAttributeValue && !(this.get('attribute.type') === 'date')) {
      let value = this.currentAttributeValue.toString();
      return value.match(/^https?:\/\//) || value.match(/^www\./) || value.split('.').length >= 2;
    }
  }),
  linkUrl: computed('currentAttributeValue', 'attribute.isEmail', function () {
    let value = this.currentAttributeValue.toString();
    if (value.match(/^https?:\/\//)) {
      return value;
    } else if (this.get('attribute.isEmail')) {
      value = value.split('@').get('lastObject');
    }
    return `//${value}`;
  }),

  editorComponentPath: computed('attribute.type', function () {
    if (this.get('attribute.isLanguageOverride')) {
      return `people/attributes/language-override-editor`;
    }

    if (isPresent(this.get('attribute.options'))) {
      return `people/attributes/multiple-choice-editor`;
    }

    if (this.get('attribute.identifier') === 'company.name') {
      return `people/profile/editable-company-name`;
    }

    let dataType = this.get('attribute.type');
    if (KNOWN_DATA_TYPES.includes(dataType)) {
      return `people/attributes/${this.editorDataTypeMap[dataType]}-editor`;
    }
  }),

  init() {
    this._super(...arguments);
    if (this.get('attribute.isLanguageOverride')) {
      this.fetchLanguageSettings.perform();
    }
  },

  fetchLanguageSettings: task(function* (value) {
    let languageSettings;

    let peekedLanguageSettings = this.store.peekRecord(
      'messenger-settings/languages',
      this.appService.app.id,
    );
    if (!peekedLanguageSettings) {
      let peekedMessengerSettings = this.store.peekRecord(
        'messenger-settings/all',
        this.appService.app.id,
      );
      peekedLanguageSettings = peekedMessengerSettings?.languages;
    }

    if (peekedLanguageSettings) {
      languageSettings = peekedLanguageSettings;
    } else {
      let fetchedLanguageSettings = yield this.store.findRecord(
        'messenger-settings/languages',
        this.appService.app.id,
      );
      languageSettings = fetchedLanguageSettings;
    }

    set(this, 'languageSettings', languageSettings);
  }).drop(),

  cancelEditing: action(function () {
    this.set('isEditing', false);
  }),

  actions: {
    beginEditing() {
      if (!this.disabled && !this.attributeIdentifiesUser) {
        this.set('isEditing', true);
      }
    },
    completeEditing(value) {
      if (value !== this.currentAttributeValue) {
        this._performUpdateTask('updateAttributeValue', value);
      }
      this.set('isEditing', false);
      this.set('showConfirmationModal', false);
    },
    confirmEditing(value) {
      if (this.attribute.isEmail && isPresent(value) && value !== this.currentAttributeValue) {
        this.set('copiedValue', copy(value));
        this.set('showConfirmationModal', true);
        this.confirmModalAnalyticsEvent('displayed_confirmation');
        return;
      }
      this.send('completeEditing', value);
    },
    cancelConfirmation() {
      this.set('showConfirmationModal', false);
      this.set('isEditing', false);
      this.confirmModalAnalyticsEvent('cancelled_confirmation');
    },
    resyncData() {
      this._performUpdateTask('resyncAttributeData');
    },
  },
  confirmModalAnalyticsEvent(action) {
    this.intercomEventService.trackAnalyticsEvent({
      action,
      object: 'editable_attribute',
      attribute_identifier: this.get('attribute.identifier'),
      person_role: this.get('person.role'),
    });
  },
});
