/* import __COLOCATED_TEMPLATE__ from './discount-pill.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import templateOnly from '@ember/component/template-only';

interface DiscountPillArgs {
  text: string;
  style?: 'paywall' | 'grey' | 'blue';
}

interface DiscountPillSignature {
  Args: DiscountPillArgs;
  Element: HTMLDivElement;
}

const DiscountPill = templateOnly<DiscountPillSignature>();

export default DiscountPill;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Purchase::DiscountPill': typeof DiscountPill;
  }
}
