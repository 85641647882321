/* import __COLOCATED_TEMPLATE__ from './date-editor.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-component-inheritance */
import { copy } from 'ember-copy';
import { computed } from '@ember/object';
import DropdownStyleEditor from './dropdown-style-editor';

export default DropdownStyleEditor.extend({
  classNames: ['u__relative'],
  currentValueAsDateObject: computed('currentValue', function () {
    if (this.currentValue) {
      return new Date(this.currentValue);
    }
  }),
  didReceiveAttrs() {
    this._super(...arguments);
    this.set('currentValue', copy(this.value));
  },
});
