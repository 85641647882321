/* import __COLOCATED_TEMPLATE__ from './attribute-editor.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { copy } from 'ember-copy';
import { scheduleOnce } from '@ember/runloop';

export default class AttributeEditor extends Component {
  @tracked copiedValue;

  constructor() {
    super(...arguments);
    this.copiedValue = copy(this.args.value);
  }

  focus(element) {
    element.focus();
    element.select();
  }

  completeUpdate() {
    this.args.completeEditing(this.copiedValue);
  }

  @action
  cancelEditing() {
    this.copiedValue = this.args.value;
    this.args.cancelEditing();
  }

  @action
  editingCompletedViaBlur() {
    scheduleOnce('afterRender', this, this.completeUpdate);
  }

  @action
  editingCompletedViaEnter() {
    scheduleOnce('afterRender', this, this.completeUpdate);
  }
}
