/* import __COLOCATED_TEMPLATE__ from './main-component.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { isPresent, isBlank } from '@ember/utils';
import type Store from '@ember-data/store';
import type IntlService from 'ember-intl/services/intl';
import {
  type ATTRIBUTE_DESCRIPTOR_TYPES,
  ATTRIBUTE_DESCRIPTOR_ICON_LABEL_MAP,
  ATTRIBUTE_DESCRIPTOR_TYPE_LABEL_TRANSLATION_KEY_MAP,
} from 'embercom/models/custom-objects/constants/attribute-descriptor-types';
import { STANDARD_OBJECT_TYPE_IDENTIFIER_TO_NAME_MAP } from 'embercom/models/custom-objects/constants/object-types';
import { REFERENCES_MANY, REFERENCES_ONE } from 'embercom/models/objects/constants/reference-types';
import type Attribute from 'embercom/models/attribute';
import type AttributeOption from 'embercom/models/people/attribute-option';
import type FinPersistedDataContextAttribute from 'embercom/models/fin-persisted-data-context/fin-persisted-data-attribute';
import { type LocalizedKnowledgeContent } from 'embercom/objects/knowledge-hub/localized-knowledge-content';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { type TaskGenerator } from 'ember-concurrency';
import ajax from 'embercom/lib/ajax';
import { captureException } from 'embercom/lib/sentry';
import type MessengerInstallation from 'embercom/services/messenger-installation';
import type CustomObjects from 'embercom/services/custom-objects-service';

interface Args {
  attribute: Attribute;
  finPersistedDataContextAttribute: FinPersistedDataContextAttribute;
  creatingNewAttribute: boolean | undefined | null;
  typeToCreate: string;
  availableTypes: { text: string; value: ATTRIBUTE_DESCRIPTOR_TYPES; icon: string }[] | undefined;
  showPeopleAndCompanyToggleTab: boolean;
}

interface Signature {
  Args: Args;
}

const OPTION_LIMIT = 35;
const IDENTIFIER_REPLACEMENT_REGEX = /(company\.custom_data\.|custom_data\.)(.*)/;
const ATTRIBUTES_PERMANENTLY_PREVENTED_FROM_MESSENGER_UPDATES = [
  'shopify_id',
  'shopify_accepts_marketing',
  'shopify_orders_count',
  'shopify_total_spent',
  'shopify_note',
  'shopify_last_order_name',
  'shopify_last_order_id',
  'shopify_verified_email',
  'shopify_tax_exempt',
  'shopify_state',
  'stripe_id',
  'stripe_account_balance',
  'stripe_delinquent',
  'stripe_deleted',
  'stripe_plan',
  'stripe_plan_price',
  'stripe_plan_interval',
  'stripe_plan_interval_count',
  'stripe_subscription_period_start_at',
  'stripe_subscription_status',
  'stripe_last_charge_amount',
  'stripe_last_charge_at',
  'stripe_card_expires_at',
  'stripe_card_brand',
];

export default class MainComponent extends Component<Signature> {
  @service declare appService: any;
  @service declare store: Store;
  @service declare intl: IntlService;
  @service declare customObjectsService: CustomObjects;
  @service declare messengerInstallation: MessengerInstallation;

  @tracked existingReferenceType: any = undefined;
  @tracked showEditReferenceTypeWarning = false;
  @tracked showEditCardinalityWarning = false;
  @tracked fetchedDependentObjects = false;
  @tracked dependentObjects: {
    attribute_id: string;
    attribute_name: string;
    dependent_answers: any[];
    dependent_behaviors: any[];
    dependent_custom_bots: any[];
    dependent_custom_reports: any[];
    dependent_email_templates: any[];
    dependent_qualification_attribute: any;
    dependent_rules: any[];
    dependent_rulesets: any[];
    dependent_segments: any[];
    dependent_task_bots: any[];
    dependent_team_intros: any[];
    dependent_workflow_connector_actions: any[];
  };

  originalType: string;
  originalOptions: string[];

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.originalType = this.args.attribute?.inferredType || 'string';
    this.originalOptions = (this.args.attribute?.options || []).map(
      (option: AttributeOption) => option.value,
    );
    this.dependentObjects = {
      attribute_id: '',
      attribute_name: '',
      dependent_answers: [],
      dependent_behaviors: [],
      dependent_custom_bots: [],
      dependent_custom_reports: [],
      dependent_email_templates: [],
      dependent_qualification_attribute: null,
      dependent_rules: [],
      dependent_rulesets: [],
      dependent_segments: [],
      dependent_task_bots: [],
      dependent_team_intros: [],
      dependent_workflow_connector_actions: [],
    };

    if (this.args.attribute) {
      if (!this.args.creatingNewAttribute) {
        if (isPresent(this.args.attribute.reference)) {
          this.args.attribute.referencedObjectTypeIdentifier =
            this.args.attribute?.referencedObjectTypeIdentifier;
          this.args.attribute.referenceType = this.args.attribute?.referenceType || REFERENCES_MANY;
          this.existingReferenceType = this.args.attribute?.referenceType;
        }
      }
    }
  }

  get localizedKnowledgeContent() {
    return this.args.finPersistedDataContextAttribute as unknown as LocalizedKnowledgeContent;
  }

  get optionLimit() {
    return OPTION_LIMIT;
  }

  get validationTitle() {
    return this.intl.t(
      `people.attributes.detail-view.main-component.validation.${this.args.attribute.identifier}.title`,
    );
  }

  get validationDescription() {
    return this.intl.t(
      `people.attributes.detail-view.main-component.validation.${this.args.attribute.identifier}.description`,
    );
  }

  get relationshipDataTypeEnabled() {
    if (!this.args.creatingNewAttribute && this.args.attribute) {
      return this.args.attribute.isUser;
    } else if (this.args.showPeopleAndCompanyToggleTab) {
      return false;
    } else {
      return this.args.typeToCreate === 'people';
    }
  }

  get typeDropdownItems() {
    if (this.args.availableTypes) {
      return this.args.availableTypes;
    }
    if (this.relationshipDataTypeEnabled) {
      return this.defaultTypes;
    } else {
      return this.defaultTypes.rejectBy('value', 'relationship');
    }
  }

  get readableTypeName() {
    let inferredType = this.args.attribute?.inferredType;
    if (isPresent(inferredType)) {
      return this.typeDropdownItems.find((item: any) => item.value === inferredType)!.text;
    } else {
      return this.intl.t('people.attributes.unknown');
    }
  }

  get defaultTypes() {
    return ['string', 'fixnum', 'float', 'boolean', 'date', 'options', 'relationship'].map(
      (type: keyof typeof ATTRIBUTE_DESCRIPTOR_ICON_LABEL_MAP) => ({
        text: this.intl.t(ATTRIBUTE_DESCRIPTOR_TYPE_LABEL_TRANSLATION_KEY_MAP[type]),
        value: type,
        icon: ATTRIBUTE_DESCRIPTOR_ICON_LABEL_MAP[type],
      }),
    );
  }

  get sourceObjectTypeName() {
    return STANDARD_OBJECT_TYPE_IDENTIFIER_TO_NAME_MAP[
      this.args.attribute
        .objectTypeIdentifier as keyof typeof STANDARD_OBJECT_TYPE_IDENTIFIER_TO_NAME_MAP
    ];
  }

  get hasChangedAttributeType() {
    return this.originalType !== this.args.attribute?.inferredType;
  }

  get dependentActions() {
    if (this.args.attribute && !this.args.attribute.archived) {
      if (!this.fetchedDependentObjects && this.args.attribute.cdaId) {
        taskFor(this.fetchDependentObjects).perform();
      }

      if (this.dependentObjects) {
        return this.dependentObjects.dependent_workflow_connector_actions;
      } else {
        return [];
      }
    } else {
      return [];
    }
  }

  get isFetchingDependentObjects() {
    return taskFor(this.fetchDependentObjects).isRunning;
  }

  get isAttributePermanentlyPreventedFromMessengerUpdates() {
    return ATTRIBUTES_PERMANENTLY_PREVENTED_FROM_MESSENGER_UPDATES.includes(
      this.args.attribute?.name,
    );
  }

  get isIdvEnabled() {
    return this.messengerInstallation.isIdvEnabled;
  }

  get finAiToggleEnabled() {
    return this.args.attribute.preventUpdateFromMessenger && this.isIdvEnabled;
  }

  @action
  addOption(value = '') {
    let newOption = this.store.createRecord('people/attribute-option', { value });
    this.args.attribute.options.pushObject(newOption);
  }

  @action
  updateType(type: string) {
    this.args.attribute.options = [];
    if (type === 'options') {
      this.originalOptions.forEach((value) => this.addOption(value));
      if (this.args.attribute.options.length === 0) {
        this.addOption();
        this.addOption();
      }
    }
    this.args.attribute.type = type;
  }

  @action
  removeOption(index: number) {
    if (this.args.attribute.options.length > 2) {
      this.args.attribute.options.removeAt(index);
    }
  }

  @action
  updateNameAndIdentifier(e: Event | string) {
    let text = typeof e === 'string' ? e : (e.target as HTMLInputElement)?.value;

    if (this.args.creatingNewAttribute) {
      let updatedIdentifier = this.args.attribute.identifier.replace(
        IDENTIFIER_REPLACEMENT_REGEX,
        `$1${text}`,
      );
      this.args.attribute.name = text;
      this.args.attribute.identifier = updatedIdentifier;
    }
  }

  @action
  updateReferencedObjectTypeIdentifier(referencedObjectTypeIdentifier: string) {
    if (isBlank(this.args.attribute.reference)) {
      this.args.attribute.reference = this.store.createRecord('objects/reference', {
        referencedObjectTypeIdentifier,
        referenceType: REFERENCES_MANY,
      });
    } else {
      this.args.attribute.referencedObjectTypeIdentifier = referencedObjectTypeIdentifier;
    }
  }

  @action
  onReferenceTypeToggle() {
    this.args.attribute.referenceType =
      this.args.attribute.referenceType === REFERENCES_MANY ? REFERENCES_ONE : REFERENCES_MANY;

    let shouldShowEditReferenceTypeWarning = (!this.args.creatingNewAttribute &&
      this.args.attribute.referenceType &&
      this.args.attribute.referenceType !== this.existingReferenceType) as boolean;

    this.showEditReferenceTypeWarning = shouldShowEditReferenceTypeWarning;
  }

  @action
  onPreventUpdateFromMessengerChange() {
    this.args.attribute.preventUpdateFromMessenger =
      !this.args.attribute.preventUpdateFromMessenger;
  }

  @action
  onFinPersistedDataContextAttributeIsLiveChange() {
    this.args.finPersistedDataContextAttribute.isFinLive =
      !this.args.finPersistedDataContextAttribute.isFinLive;
  }

  @action
  toggleValidation() {
    this.args.attribute.validationEnabled = !this.args.attribute.validationEnabled;
  }

  @action
  toggleCompany(changingToCompany: boolean) {
    let regexReplacementString = changingToCompany ? `company.custom_data.$2` : `custom_data.$2`;
    let updatedIdentifier = this.args.attribute.identifier.replace(
      IDENTIFIER_REPLACEMENT_REGEX,
      regexReplacementString,
    );
    this.args.attribute.identifier = updatedIdentifier;
  }

  @task({ restartable: true })
  *fetchDependentObjects(): TaskGenerator<void> {
    try {
      let response = yield ajax({
        url: `/ember/data_attributes/${this.args.attribute.cdaId}/dependent_objects`,
        type: 'GET',
        data: { app_id: this.appService.app.id },
      });

      this.fetchedDependentObjects = true;
      this.dependentObjects = response.table;
    } catch (e) {
      // This is used for a warning, so it's better to fail silently and not show anything
      captureException(e);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'People::Attributes::DetailView::MainComponent': typeof MainComponent;
    'people/attributes/detail-view/main-component': typeof MainComponent;
  }
}
