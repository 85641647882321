/* import __COLOCATED_TEMPLATE__ from './select-item.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */
import Component from '@glimmer/component';
import { dropTask } from 'ember-concurrency-decorators';

export default class SelectItem extends Component {
  @dropTask
  *openUpgradeModal() {
    yield this.args.hideDropdownOverlay();
    yield this.args.item.paywallOpenUpgradeModal();
  }
}
