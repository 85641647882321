/* import __COLOCATED_TEMPLATE__ from './pinnable-search-modal.hbs'; */
/* RESPONSIBLE TEAM: team-product-exploration */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

interface Args {
  onClick: (segment: any) => void;
  onPinSelected: (action: 'show' | 'hide', segment: any) => void;
  onQueryChanged: (query: string) => void;
  hiddenSegments: any[];
  visibleSegments: any[];
  nonEditableSegments: any[];
  query: string;
  noResultsLabel: string;
  searchPlaceholder: string;
  footerButtonLabel: string;
  footerTransitionRoute: string;
}

interface Signature {
  Args: Args;
  Element: never;
}

interface SegmentItem {
  segment: any;
  isVisible: boolean;
  hideIcon?: boolean;
}

export default class PinnableSearchModal extends Component<Signature> {
  @service declare intercomEventService: any;

  get hasResults() {
    return this.results.length > 0;
  }

  get allSegments(): Array<SegmentItem> {
    let sortedSegments = [
      ...this.args.visibleSegments.map((segment) => ({ segment, isVisible: true })),
      ...this.args.hiddenSegments.map((segment) => ({ segment, isVisible: false })),
    ].sort((a, b) => a.segment.name.localeCompare(b.segment.name));
    return [
      ...this.args.nonEditableSegments.map((segment) => ({
        segment,
        isVisible: false,
        hideIcon: true,
      })),
      ...sortedSegments,
    ];
  }

  get results() {
    return this.allSegments;
  }

  @action onInput(event: KeyboardEvent) {
    if (event.target) {
      this.args.onQueryChanged((event.target as HTMLInputElement).value);
    }
  }

  @action onSegmentPinClicked(item: SegmentItem) {
    this.args.onPinSelected(item.isVisible ? 'hide' : 'show', item.segment);
  }

  @action onSegmentSelected(item: SegmentItem, hideCallback: () => void) {
    this.args.onClick(item.segment);
    hideCallback();
  }

  @action onItemClicked(item: any) {
    this.args.onClick(item.segment);
  }

  @action resetModal() {
    this.args.onQueryChanged('');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    PinnableSearchModal: typeof PinnableSearchModal;
  }
}
