/* import __COLOCATED_TEMPLATE__ from './language-override-editor.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';
import type Session from 'embercom/services/session';
import { action } from '@ember/object';

import Component from '@glimmer/component';
import type Attribute from 'embercom/objects/inbox/attribute';

type Args = {
  attribute: Attribute;
  value: string;
  completeEditing: () => void;
  cancelEditing: () => void;
  languageSettings: any;
};

interface Locale {
  localeId: string;
  name: string;
}

interface Signature {
  Args: Args;
}

export default class LanguageOverrideEditor extends Component<Signature> {
  @service appService: $TSFixMe;
  @service declare store: Store;
  @service declare session: Session;

  get allAvailableLocales() {
    return this.args.languageSettings?.availableLocales || [];
  }

  get permittedLocales() {
    return this.args.languageSettings?.permittedLocales || [];
  }

  get selectOptions() {
    return this.permittedLocales.map((locale: Locale) => locale.name);
  }

  @action
  valueFromString(string: string) {
    let locale = this.allAvailableLocales.find((locale: Locale) => locale.name === string);
    return locale?.localeId;
  }

  @action
  stringFromValue(value: string) {
    let locale = this.allAvailableLocales.find((locale: Locale) => locale.localeId === value);
    return locale?.name;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'People::Attributes::LanguageOverrideEditor': typeof LanguageOverrideEditor;
    'people/attributes/languge-override-editor': typeof LanguageOverrideEditor;
  }
}
