/* import __COLOCATED_TEMPLATE__ from './default-price-row.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

interface Args {
  hasValueBasedPricing: boolean;
  priceRow: any;
  product: any;
}

export default class PriceRowComponent extends Component<Args> {
  @tracked hasValueBasedPricing: any;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Paywalls::UpgradeModal::PriceRow::DefaultPriceRow': typeof PriceRowComponent;
    'paywalls/upgrade-modal/price-row/default-price-row': typeof PriceRowComponent;
  }
}
