/* import __COLOCATED_TEMPLATE__ from './product-icon-tooltip.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    productName: string;
    logoAnalyticsMetadata?: Record<string, any>;
    animationDuration?: number;
    trackHoverObject?: string;
    classNames?: string;
    shouldHideToolTip?: boolean;
  };
  Blocks: {
    default: [];
  };
}

const ProductIconTooltip = templateOnlyComponent<Signature>();
export default ProductIconTooltip;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'PrimaryNav::ProductIconTooltip': typeof ProductIconTooltip;
  }
}
