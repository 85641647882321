/* import __COLOCATED_TEMPLATE__ from './frozen-for-non-payment.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { showNewMessageInIntercomWidget } from 'embercom/lib/intercom-widget-helper';
import { action } from '@ember/object';

export default class FrozenForNonPayment extends Component {
  @service purchaseAnalyticsService;
  @service customerService;

  get activePlanIds() {
    return this.customerService.products
      .flatMap((prod) => prod.plans)
      .filterBy('active')
      .mapBy('idAsNumber');
  }

  @action openMessengerForAccountFreeze() {
    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      object: 'chat_with_accounting_account_frozen_button',
      planIds: this.activePlanIds,
      context: 'change_plan_modal',
    });

    showNewMessageInIntercomWidget(
      'Hi, my workspace has been restricted due to non-payment of invoices. Please connect me with the Accounts Receivable team to arrange a payment.',
    );
  }
}
