/* import __COLOCATED_TEMPLATE__ from './boolean-editor.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class BooleanEditor extends Component {
  @service intl;

  get selectOptions() {
    return [
      this.intl.t('people.attributes.boolean-editor.true'),
      this.intl.t('people.attributes.boolean-editor.false'),
    ];
  }

  @action
  valueFromString(value) {
    switch (value) {
      case this.intl.t('people.attributes.boolean-editor.true'):
        return true;
      case this.intl.t('people.attributes.boolean-editor.false'):
        return false;
      default:
        return null;
    }
  }

  @action
  stringFromValue(value) {
    switch (value) {
      case true:
        return this.intl.t('people.attributes.boolean-editor.true');
      case false:
        return this.intl.t('people.attributes.boolean-editor.false');
      default:
        return this.intl.t('people.attributes.add');
    }
  }
}
