/* import __COLOCATED_TEMPLATE__ from './prosemirror.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import createFragmentFromBlock from 'embercom/lib/blocks/create-fragment-from-block';
import { action } from '@ember/object';
import { isPresent } from '@ember/utils';
import { task } from 'ember-concurrency-decorators';
import AttributeInfoResolver from 'embercom/lib/common/template-attribute-resolver';
import {
  BaseConfig,
  BlocksDocument,
  INPUT_RULE_CODE_BLOCK,
  INPUT_RULE_EMOJI,
  INPUT_RULE_INLINE_CODE,
  INPUT_RULE_ORDERED_LIST,
  INPUT_RULE_UNORDERED_LIST,
  INPUT_RULE_MARKDOWN_ANCHOR,
} from '@intercom/embercom-prosemirror-composer';
import { EMOJI_TYPEAHEAD } from '@intercom/embercom-prosemirror-composer';
import { EmbercomCanvasNetworkClient } from 'embercom/objects/composer/embercom-canvas-network-client';
import EmbercomFileUploader from 'embercom/lib/articles/embercom-file-uploader';
import IfElseStatementBlockEditorComposerConfig from 'embercom/objects/composer/config/post-if-else-statement-block-editor';
import { hasMobileSdkInstalled } from 'embercom/lib/mobile/sdk-version-check';

const INTERCOM_NEWS_PACKAGE_ID = 'intercom-news';
const INTERCOM_TOUR_PACKAGE_ID = 'intercom-tours';

class ComposerConfig extends BaseConfig {
  autoFocus = false;
  allowedBlocks = [
    'paragraph',
    'image',
    'heading',
    'subheading',
    'codeBlock',
    'button',
    'orderedList',
    'unorderedList',
    'video',
    'messengerCard',
    'videoFile',
    'ifElseStatement',
    'facebookLikeButton',
    'twitterFollowButton',
  ];
  allowImplicitMarginParagraphs = true;
  allowTextAlignment = true;
  canvasNetworkClient;
  inserters = {
    appInserter: 'composer/inserters/app-inserter',
    emojiInserter: 'composer/inserters/emoji-inserter',
    ifElseStatementInserter: {
      name: 'composer/if-else-statement-block-editor',
      options: {
        helpUrl: 'https://www.intercom.com/help/en/articles/6359569-using-dynamic-content-in-chat',
      },
    },
  };
  experimental = {
    hideInsertersOnMouseOut: true,
  };
  inputRules = [
    INPUT_RULE_CODE_BLOCK,
    INPUT_RULE_EMOJI,
    INPUT_RULE_ORDERED_LIST,
    INPUT_RULE_UNORDERED_LIST,
    INPUT_RULE_INLINE_CODE,
    INPUT_RULE_MARKDOWN_ANCHOR,
  ];
  placeholder = '';
  textDirection = 'ltr';
  tools = [
    { name: 'text-formatter' },
    { name: 'template-inserter' },
    { name: 'anchor-editor', options: { showHelpLinkHeader: true } },
    { name: 'button-editor', options: { showHelpLinkHeader: true } },
    { name: 'fallback-editor' },
    { name: 'media-inserter' },
    { name: 'if-else-statement-editor' },
    { name: 'twitter-follow-block-editor' },
    { name: 'facebook-like-block-editor' },
  ];
  typeaheads = [EMOJI_TYPEAHEAD];
  upload = {
    allowedImageFileTypes: ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'],
    allowedVideoFileTypes: ['video/mp4'],
    allowedAttachmentTypes: ['*'],
    uploader: EmbercomFileUploader,
    attrs: { policyUrl: '' },
  };

  constructor({ app, eventService, resolver }) {
    super();

    this.analytics = {
      trackAnalyticsEvent: (eventName, attrs) => {
        eventService.trackAnalyticsEvent({
          action: eventName,
          object: 'post',
          place: 'composer',
          ...attrs,
        });
      },
    };

    this.tools = this.tools.concat({
      name: 'image-editor',
      options: { supportAltAttributeEditing: app.canUseAdvancedEmailFormatting },
    });

    this.canvasNetworkClient = new EmbercomCanvasNetworkClient(app.id);

    this.inserters.ifElseStatementInserter.options.composerConfig =
      new IfElseStatementBlockEditorComposerConfig({ app, eventService, resolver });

    this.templating = { picker: 'common/attribute-picker', resolver };
    this.upload.attrs.policyUrl = `/apps/${app.id}/uploads`;
  }

  setupContentAnchorEditor() {
    this.tools = [
      ...this.tools.filter((x) => x.name !== 'anchor-editor'),
      { name: 'content-anchor-editor', options: { showHelpLinkHeader: true } },
    ];
    this.experimental = {
      ...this.experimental,
      showInsertAnchorOption: true,
    };
  }
}

export default class ProsemirrorPostComposer extends Component {
  blocksDoc;
  @service appService;
  @service attributeService;
  @service contentEditorService;
  @service intercomEventService;
  @service store;

  @tracked messengerApps = [];

  constructor() {
    super(...arguments);
    this.blocksDoc = new BlocksDocument(this.localizedPostContent.blocks);
    this.fetchMessengerApps.perform();
  }

  get app() {
    return this.appService.app;
  }

  get composerConfig() {
    let config = new ComposerConfig({
      app: this.app,
      eventService: this.intercomEventService,
      resolver: this.resolver,
    });

    if (this.canUseSmartLinks) {
      config.setupContentAnchorEditor();
    }

    if (this.canInsertNews) {
      config.inserters.newsInserter = 'composer/inserters/news-inserter';
    }

    if (this.canInsertTours) {
      config.inserters.tourInserter = 'composer/inserters/tour-inserter';
    }

    config.upload.onUploadStart = this.contentEditorService.uploadFileStarted;
    config.upload.onUploadFinish = this.contentEditorService.uploadFileFinished;

    return config;
  }

  get canUseSmartLinks() {
    return (
      this.app.canInsertSmartLinkInPost &&
      (this.hasNotMobileSdkInstalled || this.app.canOverrideMobileSmartLinkCondition)
    );
  }

  get hasNotMobileSdkInstalled() {
    return !hasMobileSdkInstalled(this.app);
  }

  get localizedPostContent() {
    return this.args.post?.localizedPostContents?.firstObject;
  }

  get resolver() {
    return new AttributeInfoResolver({
      attributes: this.attributeService.userEditorAndMetadataAttributes,
      includeAppAttributes: true,
      selectedEvents: this.selectedEvents,
    });
  }

  get selectedEvents() {
    let selectedEvent = this.contentEditorService.selectedEvent;
    return selectedEvent ? [selectedEvent] : [];
  }

  get serializedBlocks() {
    return this.localizedPostContent?.blocks.serialize();
  }

  @action updateBlocks(blocksDoc) {
    let blockFragments = blocksDoc.blocks.map((block) =>
      createFragmentFromBlock(this.store, block),
    );
    this.localizedPostContent.blocks = blockFragments;
  }

  get canInsertNews() {
    return this.appService.app.canUseNews && isPresent(this.newsMessengerApp);
  }

  get canInsertTours() {
    return isPresent(this.toursMessengerApp);
  }

  get toursMessengerApp() {
    return this.getAppPackage(INTERCOM_TOUR_PACKAGE_ID);
  }

  get newsMessengerApp() {
    return this.getAppPackage(INTERCOM_NEWS_PACKAGE_ID);
  }

  getAppPackage(packageId) {
    return this.messengerApps.find((app) => app.app_package_id === packageId);
  }

  @task({ drop: true })
  *fetchMessengerApps() {
    let messengerApps = yield this.store.query('messenger-app', { restrict_to: 'messages' });
    this.messengerApps = messengerApps;
  }
}
