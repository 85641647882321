/* import __COLOCATED_TEMPLATE__ from './qualification-attribute-list.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { alias, readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { findByProperty } from '@intercom/pulse/lib/computed-properties';

export default Component.extend({
  classNames: ['attribute__qualification-list'],

  appService: service(),
  app: alias('appService.app'),

  adminPermission: findByProperty('app.currentAdmin.permissions', 'app_id', 'app.id'),
  adminCanChangeSettings: readOnly('adminPermission.can_access_product_settings'),
});
