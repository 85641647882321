/* import __COLOCATED_TEMPLATE__ from './attribute-select.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { computed } from '@ember/object';
import { mapBy } from '@ember/object/computed';
import Component from '@ember/component';
export default Component.extend({
  tagName: 'span',

  attributeIdsInUse: mapBy('attributesInUse', 'identifier'),
  attributeGroupList: computed(
    'attributeIdsInUse.[]',
    'availableAttributes.[]',
    'selectedAttributeIdentifier',
    'label',
    function () {
      let takenAttributeIds = this.attributeIdsInUse.reject((attributeId) => {
        return attributeId === this.selectedAttributeIdentifier;
      });
      let attributes = this.availableAttributes.reject((attribute) =>
        takenAttributeIds.includes(attribute.get('identifier')),
      );

      return [
        {
          heading: this.label,
          attributes,
        },
      ];
    },
  ),
});
