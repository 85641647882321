/* import __COLOCATED_TEMPLATE__ from './sidebar.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { replyTypeOptions, formatOptions, defaultReactions } from 'embercom/models/posts/post';
import { action } from '@ember/object';
import { sentAsOptions } from 'embercom/models/data/outbound/constants';
import { preferredDevices } from 'embercom/models/data/outbound/constants';

export default class Sidebar extends Component {
  @service appService;

  replyTypeOptions = replyTypeOptions;
  sentAsOptions = sentAsOptions;
  formatOptions = formatOptions;

  get app() {
    return this.appService.app;
  }

  resetFollowUpActions() {
    let followUpActions = this.args.post.localizedPostContents.firstObject.followUpActions;
    followUpActions.answerBotEnabled = false;
    followUpActions.leadQualificationEnabled = false;
  }

  get showPushMessageToggle() {
    return (
      this.args.post.preferredDevices.includes(preferredDevices.ios) ||
      this.args.post.preferredDevices.includes(preferredDevices.android)
    );
  }

  @action
  updateReplyType(replyOption) {
    this.args.post.replyType = replyOption;

    if (replyOption === replyTypeOptions.reaction) {
      this.resetFollowUpActions();
      if (this.args.post.reactionSet.length === 0) {
        this.args.post.reactionSet = defaultReactions;
      }
    } else {
      this.args.post.reactionSet = [];
    }
  }

  @action
  changePushNotificationOption() {
    this.args.post.sendPushNotification = !this.args.post.sendPushNotification;
  }
}
