/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable ember/no-jquery */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { computed } from '@ember/object';
import $ from 'jquery';
import Component from '@ember/component';
export default Component.extend({
  tagName: 'input',
  type: 'radio',
  attributeBindings: ['name', 'type', 'value', 'checked:checked', 'disabled', 'data-test-id'],
  click() {
    if (this.boolean) {
      this.set('selection', $(this.element).val() === 'true');
    } else {
      this.set('selection', $(this.element).val());
    }
    let onSelectionChanged = this.onSelectionChanged;
    if (onSelectionChanged) {
      onSelectionChanged(this.selection);
    }
  },
  checked: computed('selection', 'value', function () {
    return this.value === this.selection;
  }),
});
