/* import __COLOCATED_TEMPLATE__ from './time-and-location.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { readOnly, and, notEmpty, equal, or } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { computed } from '@ember/object';
export default Component.extend({
  attributeService: service(),

  city: readOnly('person.geoip_data.city_name'),
  country: readOnly('person.geoip_data.country_name'),
  regionCode: readOnly('person.geoip_data.region_code'),
  hasCityAndRegionAndCountry: and('city', 'regionCode', 'country'),
  hasCityAndCountry: and('city', 'country'),
  hasCountry: notEmpty('country'),
  hasRegion: notEmpty('regionName'),
  isUSAddress: equal('person.geoip_data.country_code2', 'US'),
  isUSAddressWithCityRegionAndCountry: and('isUSAddress', 'hasCityAndRegionAndCountry'),
  hasTime: readOnly('person.hasTimezone'),
  hasTimeOrLocation: or('hasCountry', 'hasTime'),
  hasTimeAndLocation: and('hasCountry', 'hasTime'),

  formattedLocation: computed(
    'isUSAddressWithCityRegionAndCountry',
    'hasCityAndCountry',
    'city',
    'country',
    'isUSAddress',
    'regionCode',
    function () {
      if (this.isUSAddressWithCityRegionAndCountry) {
        return `${this.city}, ${this.regionCode}, ${this.country}`;
      } else if (this.hasCityAndCountry) {
        return `${this.city}, ${this.country}`;
      } else {
        return this.country;
      }
    },
  ),
});
