/* import __COLOCATED_TEMPLATE__ from './hover-inner.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */
import templateOnlyComponent from '@ember/component/template-only';

export interface PaywallHoverInnerArgs {
  canSelfServeTrials: boolean;
  customFeatureHeading?: string;
  customFeatureText?: string;
  addonName: string;
  feature: { name: string };
  openUpgradeModal: () => void;
  customUpgradeButtonLabel?: string;
}

interface PawallHoverInnerSignature {
  Element: HTMLElement;
  Args: PaywallHoverInnerArgs;
}

const PaywallHoverInnerComponent = templateOnlyComponent<PawallHoverInnerSignature>();
export default PaywallHoverInnerComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Paywalls::Private::HoverInner': typeof PaywallHoverInnerComponent;
  }
}
