/* import __COLOCATED_TEMPLATE__ from './upgrade-modal.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { CORE_STARTER_BASE_ID } from 'embercom/lib/billing';

export default class UpgradeModal extends Component {
  @service appService;

  get app() {
    return this.appService.app;
  }

  get showLinkToBuildSubscription() {
    return this.args.plan.id === CORE_STARTER_BASE_ID && this.app.hasNoActiveSubscription;
  }
}
