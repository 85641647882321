/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Args {
  isDowngrade: boolean;
  selectedPlanName?: string;
  openedFromBillingSummary: boolean;
  header?: string;
  description?: string;
  loading?: boolean;
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

const PaywallsUpgradeModalSidebarHeader = templateOnlyComponent<Signature>();
export default PaywallsUpgradeModalSidebarHeader;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Paywalls::UpgradeModal::Sidebar::Header': typeof PaywallsUpgradeModalSidebarHeader;
  }
}
