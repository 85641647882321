/* import __COLOCATED_TEMPLATE__ from './edit-email-confirm-modal.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import { USER_ROLE_PREDICATE } from 'embercom/lib/default-predicates';
import User from 'embercom/models/user';
import { inject as service } from '@ember/service';
import Conversation from 'embercom/models/conversation';
import { isPresent, isEmpty } from '@ember/utils';
import moment from 'moment-timezone';

export default class EditEmailConfirmModal extends Component {
  @service store;
  @service appService;
  @service intercomEventService;
  @service intl;

  @tracked potentialMergeUsers;
  @tracked conversationsCount;
  @tracked lastMonthConversationsCount;

  get app() {
    return this.appService.app;
  }

  get person() {
    return this.args.person;
  }

  get newEmail() {
    return this.args.newEmail;
  }

  get previousEmail() {
    return this.args.previousEmail;
  }

  get tasksRunning() {
    return (
      this.fetchLastMonthConversations.isRunning ||
      this.fetchConversations.isRunning ||
      this.fetchMergeUser.isRunning
    );
  }

  get highVolumeConversations() {
    return this.lastMonthConversationsCount && this.lastMonthConversationsCount > 20;
  }

  get primaryLabel() {
    if (this.fetchConfirmModalData.isRunning) {
      return this.intl.t('people.attributes.edit-modal.loading');
    } else if (this.highVolumeConversations) {
      return this.intl.t('people.attributes.edit-modal.contact-support');
    } else {
      return this.intl.t('people.attributes.edit-modal.authorize-label');
    }
  }

  @action
  openIntercomWidget() {
    Intercom('show');
  }

  @action
  customConfirmAction() {
    if (this.person.isWhatsappLead && isPresent(this.potentialMergeUsers)) {
      let analyticsEvent = {
        action: 'whatsapp-lead-merged',
        object: 'edit-email-confirm-modal',
      };

      this.intercomEventService.trackAnalyticsEvent(analyticsEvent);
    }

    this.trackEditEvent();
    // call the original handler
    this.args.confirmAction();
  }

  @task({ drop: true })
  *fetchConfirmModalData() {
    this.fetchLastMonthConversations.perform();
    this.fetchConversations.perform();
    if (this.person.isLeadRole) {
      yield this.fetchMergeUser.perform();
    }
  }

  @task({ drop: true })
  *fetchMergeUser() {
    let predicates = [
      {
        attribute: 'email',
        type: 'string',
        comparison: 'eq',
        value: this.newEmail,
      },
      USER_ROLE_PREDICATE,
    ];

    let response = yield User.search(this.app, predicates, {});
    this.potentialMergeUsers = response?.users;
  }

  @task({ drop: true })
  *fetchLastMonthConversations() {
    let startDate = moment().subtract(30, 'days').format('YYYY-MM-DD');
    let lastMonthResponse = yield Conversation.newSearch({
      app_id: this.app.id,
      user_ids: [this.person.id],
      scope: 'all',
      admin_date_range_start: startDate,
      per_page: 1,
      _data_source: 'elasticsearch',
    });
    this.lastMonthConversationsCount = lastMonthResponse.total_count;
  }

  @task({ drop: true })
  *fetchConversations() {
    let response = yield Conversation.newSearch({
      app_id: this.app.id,
      user_ids: [this.person.id],
      scope: 'all',
      _data_source: 'elasticsearch',
    });
    this.conversationsCount = response.total_count;
  }

  trackEditEvent() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'edited',
      object: 'user_email_attribute',
      end_user_id: this.person?.id,
      previous_email_empty: isEmpty(this.previousEmail),
    });
  }
}
