/* import __COLOCATED_TEMPLATE__ from './attribute-value-info-tooltip.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
import { get, action } from '@ember/object';
import { inject as service } from '@ember/service';
import moment from 'moment-timezone';
import DataUpdateContext from 'embercom/models/data/data-update-context';
import Admin from 'embercom/models/admin';
import Component from '@glimmer/component';

export default class PeopleAttributesAttributeValueInfoTooltip extends Component {
  @service intercomEventService;
  @service store;

  get emailParts() {
    return this.args.value.split('@');
  }

  get dataSource() {
    return get(
      this,
      `args.model.metadata.data_sources.${this.args.attribute?.identifierWithoutCompanyPrefix}`,
    );
  }

  get admin() {
    if (this.dataSource && this.dataSource.source === DataUpdateContext.TEAMMATE_SOURCE) {
      return Admin.peekAndMaybeLoad(this.store, this.dataSource.changed_by);
    }
  }

  get dataSourceChangedAt() {
    return moment.unix(this.dataSource.changed_at).toString();
  }

  @action copiedToClipboard() {
    this.args.hideTooltip();
    this.intercomEventService.trackAnalyticsEvent({
      action: 'copied',
      object: `attribute_${this.args.attribute.name.toLowerCase()}`,
      place: 'inbox',
    });
  }
}
