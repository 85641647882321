/* import __COLOCATED_TEMPLATE__ from './editable-company-name.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable @intercom/intercom/no-component-inheritance */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-jquery */
import { isPresent, isEmpty } from '@ember/utils';
import $ from 'jquery';
import { next } from '@ember/runloop';
import { copy } from 'ember-copy';
import { A } from '@ember/array';
import EmberObject, { computed } from '@ember/object';
import { empty, not, or, gt } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import KeyboardSelectableDropdown from 'embercom/components/mixins/keyboard-selectable-dropdown';
import AttributeUpdater from 'embercom/components/mixins/people/attribute-updater';
import { findBy } from '@intercom/pulse/lib/computed-properties';
import { task, timeout } from 'ember-concurrency';
import ENV from 'embercom/config/environment';
import Company from 'embercom/models/company';
import DropdownStyleEditor from 'embercom/components/people/attributes/dropdown-style-editor';
import { action } from '@ember/object';

export default DropdownStyleEditor.extend(AttributeUpdater, KeyboardSelectableDropdown, {
  classNames: ['data-test-editable-company-name'],
  notificationsService: service(),
  attributeService: service(),
  intl: service(),
  intercomConfirmService: service(),
  selectBoxSelector: '.js__company-name',
  shouldShowCompany: false,
  hasNoCompanyAssigned: empty('person.companies'),
  hasCompanyAssigned: not('hasNoCompanyAssigned'),
  shouldShowDropdown: or('hasCompanyAssigned', 'findCompanies.isRunning', 'hasSearchResults'),
  attribute: findBy('attributeService.attributes', 'identifier', 'company.name'),
  selectableItems: computed(
    'companies',
    'findCompanies.isRunning',
    'companies.length',
    function () {
      if (this.get('findCompanies.isRunning')) {
        return [EmberObject.create({ name: 'spinner' })];
      }
      let memberCompanyIds = this.get('person.companies').mapBy('id');
      let companiesExcludingQualification = (this.companies || A()).rejectBy(
        'id',
        this.get('person.qualificationCompanyId'),
      );
      let memberCompanies = companiesExcludingQualification.filter((company) =>
        memberCompanyIds.includes(company.get('id')),
      );
      let nonMemberCompanies = companiesExcludingQualification.reject((company) =>
        memberCompanyIds.includes(company.get('id')),
      );
      let companies = memberCompanies.concat(nonMemberCompanies);
      return companies.map((company) =>
        EmberObject.create({
          id: company.get('remote_company_id'),
          name: company.get('name'),
          icon: 'company',
        }),
      );
    },
  ),
  selectedItem: null,
  hasSearchResults: gt('selectableItems.length', 0),
  noQualificationCompany: empty('person.qualificationCompanyId'),
  findCompanies: task(function* (searchTerm) {
    yield timeout(ENV.APP._500MS);
    try {
      let response = yield Company.search(
        this.app,
        [
          {
            attribute: 'company.name',
            comparison: 'contains',
            value: searchTerm.trim(),
            type: 'string',
          },
        ],
        { per_page: 20 },
      );
      this.set('companies', response.companies);
    } catch (error) {
      console.error('Error: ', error);
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t('people.profile.editable-company-name.unexpected-search-error'),
      });
    }
  }).restartable(),

  addToCompany: task(function* (remoteCompanyId) {
    try {
      let response = yield this.person.addToCompany(remoteCompanyId);
      this._updateLocalModel(response.users[0]);
      this.cancelEditing();
    } catch (error) {
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t('people.profile.editable-company-name.unexpected-add-error'),
      });
    } finally {
      this._reset();
    }
  }).restartable(),
  removeFromCompanyTask: task(function* () {
    try {
      let qualificationCompany = this.get('person.qualificationCompany');
      let name = this.get('person.displayAs');
      let response = yield this.person.removeFromCompany(qualificationCompany.get('id'));
      this._updateLocalModel(response.users[0]);
      this.notificationsService.notifyConfirmation(
        this.intl.t('people.profile.editable-company-name.remove-message', {
          userName: name,
          companyName: qualificationCompany.get('name'),
        }),
      );
      this.cancelEditing();
    } catch (error) {
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t('people.profile.editable-company-name.unexpected-remove-error'),
      });
    }
  }).enqueue(),
  // We need to overwrite the default click behavior of the dropdown style editor as otherwise
  // clicks to the addTask might be cancelled too early
  _closeOnDocumentClick(e) {
    let isInitialClick = this.isInitialClick;
    let hasClickedOutside = $(this.element).find(e.target).length === 0;
    let hasClickedOutsideWithoutClear =
      hasClickedOutside && this.hasCompanyAssigned && this.get('value.length') > 0;
    let hasClickedOutsideWithoutConfirm =
      !this.isDestroying && hasClickedOutside && !this.openingConfirm;

    if (!isInitialClick && (hasClickedOutsideWithoutClear || hasClickedOutsideWithoutConfirm)) {
      this.send('completeEditing', this.searchTerm);
    }

    this.set('isInitialClick', false);
  },
  _reset() {
    this.setProperties({
      searchTerm: copy(this.get('editableObject.name')),
      companies: A(),
    });
  },

  _focusInput() {
    next(this, function () {
      if (!this.isDestroying) {
        $('input', this.element).focus().select();
      }
    });
  },

  _confirmAddCompany(item) {
    let options = {
      title: this.intl.t('people.profile.editable-company-name.add-to-company-modal.title'),
      confirmButtonText: this.intl.t(
        'people.profile.editable-company-name.add-to-company-modal.add-to-company',
      ),
      cancelButtonText: this.intl.t(
        'people.profile.editable-company-name.add-to-company-modal.cancel',
      ),
      body: this.intl.t('people.profile.editable-company-name.add-to-company-modal.message-body', {
        userName: this.get('person.displayAs'),
        companyName: item.get('name'),
      }),
    };
    return this.intercomConfirmService.confirm(options);
  },

  _confirmExistingCompany(value) {
    let options = {
      title: this.intl.t('people.profile.editable-company-name.existing-company-modal.title', {
        companyName: value,
      }),
      confirmButtonText: this.intl.t(
        'people.profile.editable-company-name.existing-company-modal.create-new-company',
      ),
      cancelButtonText: this.intl.t(
        'people.profile.editable-company-name.existing-company-modal.use-existing-company',
      ),
      body: this.intl.t(
        'people.profile.editable-company-name.existing-company-modal.message-body',
        {
          userName: this.get('person.displayAs'),
          companyName: value,
        },
      ),
    };
    return this.intercomConfirmService.confirm(options);
  },

  _confirmEdit(value) {
    let options = {
      title: this.intl.t('people.profile.editable-company-name.edit-company-modal.title'),
      confirmButtonText: this.intl.t(
        'people.profile.editable-company-name.edit-company-modal.confirm',
      ),
      cancelButtonText: this.intl.t(
        'people.profile.editable-company-name.edit-company-modal.cancel',
      ),
      body: this.intl.t('people.profile.editable-company-name.edit-company-modal.message-body', {
        currentValue: this.currentAttributeValue,
        editedValue: value,
        userCount: this.get('editableObject.user_count'),
      }),
    };
    return this.intercomConfirmService.confirm(options);
  },

  async _performTaskOnConfirm(taskName, confirm, param) {
    this.set('openingConfirm', true);
    let isConfirmed = await confirm;
    if (isConfirmed) {
      return this.get(taskName)
        .perform(param)
        .catch(() => this._reset())
        .finally(() => this.set('openingConfirm', false));
    } else {
      this._reset();
      this.set('openingConfirm', false);
    }
  },

  async _addToExistingOrCreateCompany(value, existingCompany) {
    this.set('openingConfirm', true);
    let isConfirmed = await this._confirmAddCompany(existingCompany);
    if (!isConfirmed) {
      return;
    }
    let confirmExistingCompany = await this._confirmExistingCompany(value);
    if (confirmExistingCompany) {
      this.updateAttributeValue.perform(value);
    } else {
      this.person
        .addToCompany(existingCompany.get('remoteCompanyId'))
        .then((response) => {
          this._updateLocalModel(response.users[0]);
          this.cancelEditing();
        })
        .catch((error) => {
          this.notificationsService.notifyResponseError(error, {
            default: this.intl.t(
              'people.profile.editable-company-name.unexpected-add-company-error',
            ),
          });
        })
        .finally(() => {
          this.set('openingConfirm', false);
          this._reset();
        });
    }
  },

  didInsertElement() {
    this._super();
    this.set('searchTerm', copy(this.get('editableObject.name')));
    this._focusInput();
  },

  handleKeyUp: action(function (event) {
    if (event.keyCode === 27) {
      this.cancelEditing();
    } else if (event.keyCode === 13) {
      this.send('completeEditing', event.target.value);
    }
  }),

  actions: {
    beginEditing() {
      this.setProperties({
        searchTerm: copy(this.get('editableObject.name')),
        isEditing: true,
      });
      this._focusInput();
    },
    completeEditing(value) {
      if (this.selectedItem) {
        // This is to fix issue with bubbling events.
        // When selectedItem is present then it shouldn't show any other modal since add to company modal is already shown.
        return;
      }
      if (isPresent(this.currentAttributeValue) && isEmpty(value)) {
        this.send('removeFromCompany');
      } else if (value !== this.currentAttributeValue) {
        let companies = this.companies || [];
        let existingCompany = companies.findBy('name', value);

        if (this.noQualificationCompany && existingCompany) {
          this._addToExistingOrCreateCompany(value, existingCompany);
        } else if (this.get('editableObject.user_count') <= 1) {
          this.updateAttributeValue.perform(value);
        } else {
          this._performTaskOnConfirm('updateAttributeValue', this._confirmEdit(value), value);
        }
      } else {
        this.cancelEditing();
      }
    },
    close() {
      return;
    },
    async selectItem(item) {
      let itemId = item.get('id');
      if (itemId !== this.get('editableObject.remote_company_id')) {
        this.set('selectedItem', item);
        try {
          let isConfirmed = await this._confirmAddCompany(item);
          if (!isConfirmed) {
            return;
          }
          let response = await this.person.addToCompany(itemId);
          this._updateLocalModel(response.users[0]);
          this.cancelEditing();
        } catch (e) {
          this.notificationsService.notifyResponseError(e, {
            default: this.intl.t(
              'people.profile.editable-company-name.unexpected-add-company-error',
            ),
          });
        }
        this.set('selectedItem', null);
      }
    },
    startSearching(searchTerm) {
      this.set('searchTerm', searchTerm);
      if (searchTerm.trim() !== '') {
        this.findCompanies.perform(searchTerm);
      }
    },
    removeFromCompany() {
      this.removeFromCompanyTask.perform();
    },
  },
});
