/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable ember/no-jquery */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import $ from 'jquery';
import { addEventListener } from 'ember-lifeline';

export default Component.extend({
  classNames: ['u__relative'],

  didInsertElement() {
    this._super(...arguments);
    this.set('isInitialClick', true);
    addEventListener(this, document, 'click', this._closeOnDocumentClick);
  },

  // These components are created as a result of a click action.
  // We ingore the initial click as part of this handler. To prevent immediately closing the component.
  _closeOnDocumentClick(e) {
    let isInitialClick = this.isInitialClick;
    if (!this.isDestroying && !isInitialClick && $(this.element).find(e.target).length === 0) {
      this.cancelEditing();
    }
    this.set('isInitialClick', false);
  },
});
