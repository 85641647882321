/* import __COLOCATED_TEMPLATE__ from './detail-view.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import { readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { task } from 'ember-concurrency';
import { put, post } from 'embercom/lib/ajax';
import { REVERSE_CARDINALITY_NAME_MAP } from 'embercom/models/custom-objects/constants/relationship-cardinalities';
import { hasFeature } from 'embercom/helpers/has-feature';

const COMPANIES_ATTR_IDENTIFIER = 'companies';

export default Component.extend({
  notificationsService: service(),
  tagName: '',
  appService: service(),
  attributeService: service(),
  intl: service(),
  app: readOnly('appService.app'),
  modelDataCacheService: service(),
  store: service(),
  finPersistedDataContextService: service(),
  helpCenterService: service(),
  copiedAttribute: null,

  init() {
    this._super(...arguments);
    this.helpCenterService.maybeFetchSite();

    this.finPersistedDataContextAttribute = null;
    if (
      hasFeature('answerbot-fin-persisted-data', this.appService) &&
      this.attribute &&
      this.attribute.isCustomData &&
      this.attribute.name
    ) {
      this.setFinPersistedDataContextAttribute.perform();
    }
  },

  didInsertElement() {
    this._super(...arguments);
    this._createAttributeCopy();
  },
  willDestroyElement() {
    this._super(...arguments);
    if (this.copiedAttribute && this.copiedAttribute.isNew) {
      this.store.unloadRecord(this.copiedAttribute);
    }
  },
  _createAttributeCopy() {
    let attributeJSON = {
      type: 'string',
    };
    if (this.attribute) {
      attributeJSON = this.attribute.serialize();
      // Standarise types by converting `integer` to `fixnum` on opening the component.
      if (attributeJSON.type === 'integer') {
        attributeJSON.type = 'fixnum';
      }
      delete attributeJSON.id;
      delete attributeJSON.options;
      delete attributeJSON.relationship;
    } else {
      this.set('creatingNewAttribute', true);
      // initialize as a CDA
      attributeJSON.identifier =
        this.typeToCreate === 'company' ? 'company.custom_data.' : 'custom_data.';
      attributeJSON.tracked = false;
    }
    let copiedAttribute = this.store.createRecord('attribute', attributeJSON);
    let options = this.get('attribute.options') || [];
    options.forEach((option) => {
      let copiedOption = this.store.createRecord('people/attribute-option', {
        value: option.value,
      });
      copiedAttribute.options.pushObject(copiedOption);
    });
    copiedAttribute.relationship = this.get('attribute.relationship');
    copiedAttribute.reference = this.get('attribute.reference');
    this.set('copiedAttribute', copiedAttribute);
  },

  saveTask: task(function* () {
    if (
      this.attribute?.identifier === COMPANIES_ATTR_IDENTIFIER &&
      !this.get('copiedAttribute.preventUpdateFromMessenger') &&
      this.helpCenterService.allLiveSites.some((site) => site.ticketsPortalTurnedOn)
    ) {
      this.notificationsService.notifyError(
        this.intl.t('people.attributes.detail-view.attribute-details.cannot-disable-updates'),
      );
      return;
    }
    if (this.finPersistedDataContextAttribute) {
      yield this.finPersistedDataContextService.updateFinPersistedDataContextAttributeStatus.perform(
        this.get('app.id'),
        this.get('app.currentAdmin.id'),
        this.attribute.identifier,
        this.finPersistedDataContextAttribute.isFinLive,
      );
    }
    if (this.attribute) {
      return yield this.updateAttribute.perform();
    } else {
      return yield this.createAttribute.perform();
    }
  }).drop(),

  createAttribute: task(function* () {
    try {
      let properties = this.copiedAttribute.getProperties('name');
      let createQualificationAttribute = this.dataContext === 'qualification';
      let params = Object.assign(
        properties,
        { create_qualification_attribute: createQualificationAttribute },
        this.getCustomDataAttributeData(),
      );
      let response = yield this._post('ember/custom_data_attributes', params);
      this.store.pushPayload({ attribute: response });
      let attribute = this.store.peekRecord('attribute', response.identifier);

      this.get('attributeService.attributes').pushObject(attribute);

      let qualificationAttribute = response.qualification_attribute;
      if (qualificationAttribute) {
        let store = this.store;
        store.push(
          store.normalize('people/qualification-attribute', {
            id: qualificationAttribute.id,
            identifier: attribute.get('identifier'),
          }),
        );
      }

      this.app.updateLocalCache(this.modelDataCacheService);
      this.hooks.afterCreate(attribute);

      if (attribute.isRelationshipDataType) {
        yield attribute.relatedAttribute; //Cache related attribute
      }
      return attribute;
    } catch (error) {
      console.error(error);
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t('people.attributes.detail-view.unexpected-create-error'),
      });
    }
  }).drop(),

  updateAttribute: task(function* () {
    try {
      let response = yield this._put(this.getAttributeUpdateUrl(), this.getAttributeData());

      this.store.pushPayload({ attribute: response });
      this.app.updateLocalCache(this.modelDataCacheService);
      this.hooks.afterUpdate(this.attribute);
    } catch (error) {
      console.error(error);
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t('people.attributes.detail-view.unexpected-update-error'),
      });
    }
  }).drop(),

  setFinPersistedDataContextAttribute: task(function* () {
    let attribute =
      yield this.finPersistedDataContextService.getOrCreateFinPersistedDataContextAttribute.perform(
        this.get('app.id'),
        this.get('app.currentAdmin.id'),
        this.attribute.identifier,
      );
    this.set('finPersistedDataContextAttribute', attribute);
  }).drop(),

  updateFinPersistedDataContextAttributeStatus: task(function* () {
    try {
      yield this.finPersistedDataContextService.updateFinPersistedDataContextAttributeStatus.perform(
        this.get('app.id'),
        this.get('app.currentAdmin.id'),
        this.attribute.identifier,
        this.finPersistedDataContextAttribute.isFinLive,
      );
    } catch (error) {
      console.error('Failed to update finPersistedDataContextAttribute:', error);
    }
  }).drop(),

  getAttributeUpdateUrl() {
    return this.attribute.isCustomData
      ? `ember/custom_data_attributes/${this.attribute.cda_id}`
      : `ember/standard_data_attributes/${this.attribute.identifier}`;
  },

  getAttributeData() {
    return this.attribute.isCustomData
      ? this.getCustomDataAttributeData()
      : this.getStandardAttributeData();
  },

  getCustomDataAttributeData() {
    let paramData = {
      app_id: this.get('app.id'),
      admin_id: this.get('app.currentAdmin.id'),
      data_type: this.get('copiedAttribute.inferredType'),
      options: this.get('copiedAttribute.options').map((option) => ({ value: option.value })),
      description: this.get('copiedAttribute.description'),
      company: this.get('copiedAttribute.isCompany'),
      prevent_update_from_messenger: this.get('copiedAttribute.preventUpdateFromMessenger'),
    };
    if (paramData.data_type === 'relationship') {
      paramData.reference = {
        reference_type: this.get('copiedAttribute.reference.referenceType'),
        referenced_object_type_identifier: this.get(
          'copiedAttribute.reference.referencedObjectTypeIdentifier',
        ),
      };
    }
    return paramData;
  },

  getStandardAttributeData() {
    return {
      app_id: this.get('app.id'),
      prevent_update_from_messenger: this.get('copiedAttribute.preventUpdateFromMessenger'),
      validation_enabled: this.get('copiedAttribute.validationEnabled'),
    };
  },

  // Visible for testing
  _put() {
    return put(...arguments);
  },

  _post() {
    return post(...arguments);
  },

  _cardinality() {
    if (
      this.get('copiedAttribute.relationship.id') &&
      this.get('copiedAttribute.isDestinationRelationshipAttribute')
    ) {
      return REVERSE_CARDINALITY_NAME_MAP[this.get('copiedAttribute.relationshipCardinality')];
    } else {
      return this.get('copiedAttribute.relationshipCardinality');
    }
  },
});
