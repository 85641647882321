/* import __COLOCATED_TEMPLATE__ from './app-switcher.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
import Component from '@glimmer/component';
import EmberObject from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';

export default class AppSwitcherPopover extends Component {
  @service appService;

  @tracked productionAppsFilter = '';

  get app() {
    return this.appService.app;
  }

  get admin() {
    return this.app?.currentAdmin;
  }

  get currentAppId() {
    return this.app?.id;
  }

  get productionApps() {
    return this.admin?.apps.filter((app) => !app.is_test_app) || [];
  }

  get allowWorkspaceFilter() {
    return this.productionApps.length > 5;
  }

  get filteredProductionApps() {
    if (isEmpty(this.productionAppsFilter)) {
      return this.productionApps;
    }
    let regexp = new RegExp(this.productionAppsFilter, 'i');
    return this.productionApps.filter((app) => regexp.test(app.name));
  }

  get sortedProductionApps() {
    return [...this.filteredProductionApps].sort((a, b) => a.name.localeCompare(b.name));
  }

  get testApps() {
    return this.admin?.apps.filter((app) => Boolean(app.is_test_app)) || [];
  }

  get groupedApps() {
    return this.sortedProductionApps.map((app) => {
      let testApp = this._findTestApp(app);
      return {
        app: this._makeMiniAppModel(app),
        testApp: typeof testApp === 'undefined' ? undefined : this._makeMiniAppModel(testApp),
      };
    });
  }

  _makeMiniAppModel(app) {
    return EmberObject.create({
      name: app.name,
      id: app.id,
      isCurrent: app.id === this.currentAppId,
    });
  }

  _findTestApp(app) {
    return this.testApps.findBy('id', app?.test_app_id);
  }

  // This function is a Popper.js modifier (https://popper.js.org/docs/v2/modifiers/)
  // that keeps the dimensions of the app switcher popover the same as those of
  // the primary nav menu popover. This behavior is a design requirement from
  // the "early 2018" brand refresh.
  matchPopoverDimensionsToMenuPopover(popperModifierData) {
    let { reference: referenceElement, popper: popperElement } = popperModifierData.state.elements;
    let referenceElementPopoverContainer = referenceElement.closest('[data-popover-content]');
    popperElement.style.width = `${referenceElementPopoverContainer.offsetWidth}px`;
    popperElement.style.height = `${referenceElementPopoverContainer.offsetHeight}px`;
    popperElement.style.display = 'flex';
  }
}
