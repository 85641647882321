/* import __COLOCATED_TEMPLATE__ from './attribute-selector.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/require-snake-case-analytics */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import { computed } from '@ember/object';
import { filterBy, mapBy, lt, and } from '@ember/object/computed';
import Component from '@ember/component';
import { greaterThanProperty } from '@intercom/pulse/lib/computed-properties';
import { inject as service } from '@ember/service';

const DISALLOWED_ATTRIBUTE_IDENTIFIERS = [
  'geoip_data.country_name',
  'geoip_data.region_name',
  'geoip_data.city_name',
  'geoip_data.timezone',
];

const SUPPORTED_ATTRIBUTE_TYPES = ['string', 'integer', 'boolean', 'float'];

export default Component.extend({
  intercomEventService: service(),
  store: service(),
  // eslint-disable-next-line @intercom/intercom/no-legacy-modal
  modalService: service(),
  tagName: '',
  allowNoAttributes: true,

  init() {
    this._super(...arguments);
  },

  editableAttributes: filterBy('attributesInUse', 'mandatoryAttribute', false),

  mandatoryAttributes: filterBy('attributesInUse', 'mandatoryAttribute', true),
  mandatoryAttributeIdentifiers: mapBy('mandatoryAttributes', 'identifier'),
  uneditableAttributes: computed('mandatoryAttributeIdentifiers', function () {
    return this.qualificationAttributes.mapBy('dataAttribute').filter((attribute) => {
      return this.mandatoryAttributeIdentifiers.includes(attribute.get('identifier'));
    });
  }),

  availableAttributes: computed('qualificationAttributes.[]', function () {
    let qualificationAttributes = this.qualificationAttributes;
    return qualificationAttributes.mapBy('dataAttribute').filter((attribute) => {
      let hasSupportedType = SUPPORTED_ATTRIBUTE_TYPES.includes(attribute.get('type'));
      let isAllowedIdentifier = !DISALLOWED_ATTRIBUTE_IDENTIFIERS.includes(
        attribute.get('identifier'),
      );

      return hasSupportedType && isAllowedIdentifier;
    });
  }),

  hasUnaddedAvailableAttributes: greaterThanProperty(
    'availableAttributes.length',
    'attributesInUse.length',
  ),
  hasLessQualificationAttributesThanMax: lt('attributesInUse.length', 4),
  canAddAttribute: and('hasLessQualificationAttributesThanMax', 'hasUnaddedAvailableAttributes'),

  addAttributeButtonIsDisabled: computed(
    'isEditable',
    'attributesInUse.@each.identifier',
    function () {
      let selectedAttributes = this.attributesInUse.mapBy('identifier');
      return !this.isEditable || selectedAttributes.includes(undefined);
    },
  ),

  actions: {
    addAttribute(e) {
      e.stopPropagation();
      this.attributesInUse.createFragment({
        justAdded: true,
      });
    },
    updateAttributeIdentifier(qualificationAttribute, attribute) {
      let identifier = attribute.get('identifier');
      qualificationAttribute.set('justAdded', false);
      let isUpdate = Boolean(qualificationAttribute.get('identifier'));
      let action = isUpdate ? 'updated' : 'added';
      this.attributeEdited(action, identifier);

      qualificationAttribute.set('identifier', identifier);
    },
    removeAttribute(qualificationAttribute) {
      let identifier = qualificationAttribute.get('identifier');
      if (identifier) {
        this.attributeEdited('deleted', identifier);
      }
      this.attributesInUse.removeFragment(qualificationAttribute);
    },
    showNewDataModal() {
      this.modalService.openModal('settings/modals/attribute-details', {
        allowArchival: false,
        dataContext: 'qualification',
      });
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: 'add-new-data',
      });
    },
  },
});
