/* import __COLOCATED_TEMPLATE__ from './render-paywall.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */
import Component from '@glimmer/component';

export interface Args {
  model: {
    launchPaywall: boolean;
    featureKey: string;
  };
}

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
}

export default class RenderPaywall extends Component<Signature> {
  get launchPaywall(): boolean {
    return this.args.model.launchPaywall;
  }

  get featureKey(): string {
    return this.args.model.featureKey;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Paywalls::RenderPaywall': typeof RenderPaywall;
    'paywalls/render-paywall': typeof RenderPaywall;
  }
}
