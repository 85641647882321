/* import __COLOCATED_TEMPLATE__ from './multiple-choice-editor.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
import Component from '@glimmer/component';
import { isEmpty } from '@ember/utils';
import { action } from '@ember/object';
import { addEventListener } from 'ember-lifeline';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import type Attribute from 'embercom/objects/inbox/attribute';
import { tracked } from '@glimmer/tracking';

type Args = {
  attribute: Attribute;
  value: any;
  selectOptions?: string[];
  stringFromValue: (value: any) => string | undefined;
  valueFromString: (string: string) => any;
  completeEditing: (value: any) => void;
  cancelEditing: () => void;
};

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
}

export default class MultipleChoiceEditor extends Component<Signature> {
  @service intl!: IntlService;
  @tracked isInitialClick: boolean | undefined;
  @tracked dropdownElement: any;

  get selectOptions() {
    if (this.args.selectOptions) {
      return this.args.selectOptions;
    } else {
      return this.args.attribute.options.mapBy('value');
    }
  }

  get emptyValue() {
    return isEmpty(this.args.value);
  }

  get defaultValue() {
    return this.intl.t('people.attributes.add-attribute', {
      attribute: this.args.attribute.name.toLowerCase(),
    });
  }

  get stringFromValue() {
    if (this.args.stringFromValue) {
      return this.args.stringFromValue(this.args.value);
    }
    return this.emptyValue ? this.defaultValue : this.args.value;
  }

  valueFromString(value: any) {
    if (this.args.valueFromString) {
      return this.args.valueFromString(value);
    }

    if (value === 'Unknown') {
      return null;
    } else {
      return value;
    }
  }

  @action
  completeEditing(value: any) {
    let valueForUpdate = this.valueFromString(value);
    this.args.completeEditing(valueForUpdate);
  }

  @action
  addCloseOnClickHandler(element: any) {
    this.isInitialClick = true;
    this.dropdownElement = element;
    addEventListener(this, document, 'click', this._closeOnDocumentClick);
  }

  // These components are created as a result of a click action.
  // We ignore the initial click as part of this handler, to prevent closing the component immediately.
  _closeOnDocumentClick(event: Event) {
    let isInitialClick = this.isInitialClick;
    if (!this.isDestroying && !isInitialClick && !this.dropdownElement.contains(event.target)) {
      console.info('Cancelling edit because of click');
      this.args.cancelEditing();
    }
    this.isInitialClick = false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'People::Attributes::MultipleChoiceEditor': typeof MultipleChoiceEditor;
    'people/attributes/multiple-choice-editor': typeof MultipleChoiceEditor;
  }
}
