/* import __COLOCATED_TEMPLATE__ from './plan-card-badge.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */
import Component from '@glimmer/component';

interface Args {
  style: 'grey' | 'black' | 'paywall';
  text: string;
}

export default class PlanCardBadgeComponent extends Component<Args> {
  get textClass(): string {
    switch (this.args.style) {
      case 'grey':
        return 'text-black';
      case 'black':
        return 'text-white';
      case 'paywall':
        return 'text-paywall-base';
    }
  }

  get backgroundClass(): string {
    switch (this.args.style) {
      case 'grey':
        return 'bg-gray-lightest';
      case 'black':
        return 'bg-black';
      case 'paywall':
        return 'bg-paywall-light';
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Paywalls::Comparison::PlanCardBadge': typeof PlanCardBadgeComponent;
    'paywalls/comparison/plan-card-badge': typeof PlanCardBadgeComponent;
  }
}
