/* import __COLOCATED_TEMPLATE__ from './feature-coming-soon.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */
import templateOnlyComponent from '@ember/component/template-only';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

export interface FeatureComingSoonArgs {
  icon?: InterfaceIconName;
  textTranslationKey: string;
  showComingSoonBadge?: boolean;
}
interface FeatureComingSoonSignature {
  Element: HTMLElement;
  Args: FeatureComingSoonArgs;
}

const FeatureComingSoonComponent = templateOnlyComponent<FeatureComingSoonSignature>();
export default FeatureComingSoonComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Paywalls::FeatureComingSoon': typeof FeatureComingSoonComponent;
  }
}
